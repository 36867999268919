<template>
  <div>
    <Grid :grid-options="gridOptions" :default-params="defaultParams"></Grid>
  </div>
</template>

<script>
import Grid from "../components/Grid"
import getGridOptions from "../components/Grid/gridOptions"
export default {
  components: {
    Grid,
  },
  data() {
    return {
      gridOptions: getGridOptions("compliance"),
      defaultParams: {
        transactions_only: true,
        statement_period: new Date().toISOString(),
      },
    }
  },
}
</script>
